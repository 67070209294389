import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import { globalActions } from 'constants/globalActions';
import timeSlotsReducer from 'redux/reducers/timeSlotsReducer';
import localeReducer from './reducers/localeReducer';
import { generateReducers } from './reduxUtils';

export default combineReducers({
  form: reduxFormReducer, // reducer to link redux form
  timeSlots: timeSlotsReducer,
  locale: localeReducer,
  ...generateReducers([...globalActions]),
});
