import React from 'react';
import { Switch } from 'react-router-dom';

import { routeGenerator } from './routes';

function App(props) {
  return <Switch>{routeGenerator(props)}</Switch>;
}

export default App;
