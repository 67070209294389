import styled from 'styled-components';
import {
  alignItems,
  borders,
  color,
  display,
  flexbox,
  justifyContent,
  margin,
  padding,
  position,
  layout,
  textAlign,
} from 'styled-system';
import {
  Container as BaseContainer,
  Row as BaseRow,
  Col as BaseCol,
} from 'styled-bootstrap-grid';

export const defaultRowMargin = '-15px';
export const defaultColPadding = '15px';

export const Div = styled.div`
  ${display}
  ${flexbox}
  ${position}
  ${margin}
  ${padding}
  ${color}
  ${justifyContent}
  ${textAlign}
  ${layout}
  ${borders}
  ${props =>
    props.noScrollbar &&
    `
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const StyledLink = styled.a`
  ${margin}
  ${padding}
  ${color}
`;

export const Hr = styled.hr`
  ${borders}
  ${color}
  ${margin}
  ${layout}
`;

export const PrimaryLightBackground = styled.div`
  background-color: ${props => props.theme.colors.paleGreen};
  height: 1000px;
  width: 100%;
`;

export const Container = styled(BaseContainer)`
  ${layout}
  ${margin}
`;

export const Row = styled(BaseRow)`
  ${display}
  ${margin}
  ${padding}
  ${justifyContent}
  ${alignItems}
  ${layout}
`;

export const Col = styled(BaseCol)`
  ${display}
  ${margin}
  ${padding}
  ${justifyContent}
  ${alignItems}
  ${layout}
`;
