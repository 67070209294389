import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import messages from './i18n/validation';

// Following https://emailregex.com/email-validation-summary/
/* eslint-disable no-control-regex, max-len */
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
/* eslint-enable no-control-regex, max-len */

const emailValidation = message => {
  return value => (value && !emailRegex.test(value) ? message : undefined);
};

const requiredValidation = message => {
  return value => (value || typeof value === 'number' ? undefined : message);
};

const requiredCheckboxesValidation = message => {
  return values => (values && values.length ? undefined : message);
};

const checkedValidation = message => {
  return value => {
    return value === true ? undefined : message;
  };
};

const phoneValidation = message => {
  return value => (value && !/^([0-9]|-)*$/.test(value) ? message : undefined);
};

const maxLength = (max, msg) => value =>
  value && value.length > max ? msg : undefined;

export const useValidation = () => {
  const intl = useIntl();
  const checked = useMemo(
    () => checkedValidation(intl.formatMessage(messages.checked)),
    [intl],
  );
  const email = useMemo(
    () => emailValidation(intl.formatMessage(messages.email)),
    [intl],
  );
  const required = useMemo(
    () => requiredValidation(intl.formatMessage(messages.required)),
    [intl],
  );
  const requiredCheckboxes = useMemo(
    () => requiredCheckboxesValidation(intl.formatMessage(messages.required)),
    [intl],
  );
  const phoneNo = useMemo(
    () => phoneValidation(intl.formatMessage(messages.phone)),
    [intl],
  );
  const maxLength255 = useMemo(
    () => maxLength(255, intl.formatMessage(messages.maxLength, { max: 255 })),
    [intl],
  );

  return {
    checked,
    email,
    required,
    requiredCheckboxes,
    phoneNo,
    maxLength255,
  };
};
