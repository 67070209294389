import * as constants from 'constants/index';

import { Multiguest } from './pages/Multiguest';
import Confirmation from './pages/Confirmation';
import CompletePage from './pages/Complete';

export const multiguestRoutes = [
  {
    component: Multiguest,
    path: `/:calendar_alias/multiguest/:multiguest_uid/:duration${constants.durations}`,
    exact: true,
    feature: 'multiguest',
  },
  {
    component: Confirmation,
    path: `/:calendar_alias/multiguest/:multiguest_uid/:duration${constants.durations}/confirmation`,
    exact: true,
    feature: 'multiguestConfirmation',
  },
  {
    component: CompletePage,
    path: `/:calendar_alias/multiguest/:multiguest_uid/:duration${constants.durations}/completed`,
    exact: true,
    feature: 'multiguestCompleted',
  },
];
