import React from 'react';
import styled from 'styled-components';
import Step1 from 'assets/img/multiguest_onboarding_step1.png';
import { isMobileDevice } from 'utils/mobileUtils';
import { Heading2 } from 'components/elements/Typography';

const CustomHeading = styled(Heading2)`
  font-weight: bold;
  padding: 50px ${props => (props.isMobile ? '10px' : '85px')} 16px;
`;

const Span = styled.span`
  padding: 0 ${props => (props.isMobile ? '10px' : '85px')};
  text-align: center;
`;

const StepImage = styled.img`
  width: 100%;
  border-radius: 5px 5px 0 0;
`;

export default function OnboardingStep1() {
  const isMobile = isMobileDevice();
  return (
    <>
      <StepImage src={Step1} />
      <CustomHeading isMobile={isMobile}>
        この予約ページでは、複数人で日程調整してます
      </CustomHeading>
      <Span isMobile={isMobile}>担当者がゲストを複数人招待しています</Span>
    </>
  );
}
