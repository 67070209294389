import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingBarSpinner = keyframes`
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled.div`
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: ${LoadingBarSpinner} 400ms linear infinite;
`;

const LoaderIcons = styled.div`
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: #00c8b1 !important;
  border-left-color: #00c8b1 !important;
  border-radius: 50%;
`;

function Loader() {
  return (
    <LoaderWrapper>
      <LoaderIcons />
    </LoaderWrapper>
  );
}

export default Loader;
