import { defineMessages } from 'react-intl';

export default defineMessages({
  checked: {
    id: 'validation.checked',
    defaultMessage: 'It is required to check this.',
  },
  email: {
    id: 'validation.email',
    defaultMessage: 'Please enter a valid email address.',
  },
  required: {
    id: 'validation.required',
    defaultMessage: 'Required field.',
  },
  phone: {
    id: 'validation.phoneNo',
    defaultMessage: 'Please enter a valid phone number from the area code.',
  },
  maxLength: {
    id: 'validation.maxLength',
    defaultMessage: 'Please enter within max of {max} characters.',
  },
});
