import React from 'react';
import { Label } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from '../../../components/utils/Helper';

const Wrapper = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;
const MessageArea = styled.p`
  height: auto;
  white-space: pre-line;
  margin-top: 5px;
`;

export default function BlockOfTextCase({ field }) {
  const { labelName, bookingCalendarCustomFieldInputs } = field;
  const message = bookingCalendarCustomFieldInputs[0]?.inputTextAreaValue;

  return (
    <Wrapper>
      <Row alignItems="center" mb="25px">
        <Col>
          <Label> {labelName} </Label>
          <MessageArea key="block-msg-area">{message}</MessageArea>
        </Col>
      </Row>
    </Wrapper>
  );
}

BlockOfTextCase.defaultProps = {};

BlockOfTextCase.propTypes = {
  field: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
