import React from 'react';
import styled from 'styled-components';
import { LOCALES } from 'hooks/useLocale';
import { getEnglishDay, getJapaneseDay } from '../../utils/momentUtils';

const CardBody = styled.div`
  padding: ${props => (props.multiguest ? '18px 18px 0' : '18px')};
  display: flex;
  flex-direction: column;
  ${props =>
    props.centerItems &&
    `
    align-items: center;
    justify-content: center;
  `}
`;

const CardDate = styled.div`
  opacity: 0.6;
  font-size: 15px;
  line-height: 1.6;
  color: #314143;
  margin-bottom: 8px;
`;

const CardTimezone = styled.div`
  opacity: 0.6;
  font-size: 12px;
  line-height: 1.4;
  color: #314143;
  margin-bottom: 8px;
`;

const CardTime = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
  color: #314143;
`;

function compare(first, next) {
  if (first.id < next.id) {
    return -1;
  }
  if (first.id > next.id) {
    return 1;
  }
  return 0;
}

export function formatDate(slot, locale = LOCALES.JA) {
  const { year, month, date, day } = slot;
  return locale === LOCALES.JA
    ? `${year}年 ${month}月${date}日 (${getJapaneseDay(day)})`
    : `${year}-${month}-${date} (${getEnglishDay(day)})`;
}

export function formatMultiTime(
  selectedSlots,
  centerItems,
  timezone,
  locale = LOCALES.JA,
) {
  const formattedSlots = {};
  let timeSlotDate = '';
  let result = [];
  const slots = [...selectedSlots];
  slots.sort(compare).map(slot => {
    const newTimeSlotDate = formatDate(slot, locale);
    if (newTimeSlotDate === timeSlotDate) {
      formattedSlots[timeSlotDate] = formattedSlots[timeSlotDate].concat(
        `${slot.time} - ${slot.nextHour}`,
      );
    } else {
      formattedSlots[newTimeSlotDate] = [`${slot.time} - ${slot.nextHour}`];
    }
    timeSlotDate = newTimeSlotDate;

    return formattedSlots;
  });
  result = Object.keys(formattedSlots).map(formattedDay => {
    return (
      <CardBody key={formattedDay} centerItems={centerItems}>
        <CardDate>{formattedDay}</CardDate>
        {formattedSlots[formattedDay].map(timeSlot => {
          return <CardTime key={timeSlot}>{timeSlot}</CardTime>;
        })}
      </CardBody>
    );
  });
  result.unshift(
    <CardBody multiguest>
      <CardTimezone>
        <i className="fa fa-globe" aria-hidden="true" /> {timezone.label}
      </CardTimezone>
    </CardBody>,
  );
  return result;
}
