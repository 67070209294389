import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useParams } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { events, pushEvent } from 'utils/GTM';

import { useAvailableTimeSlots } from '../hooks/useAvailableTimeSlots';
import {
  useMultiguestEventDetails,
  useCalendarDetails,
} from '../utils/serverUtils';

import BigCalendar from '../../../components/bigCalendar/Booking';
import Error from '../../error/pages/Error';
import Loader from '../../../components/layouts/Loader';
import Footer from '../../../components/layouts/Footer';
import formatDuration from '../../../utils/durationUtils';
import OnboardingModal from '../modules/OnboardingModal';

function MultiguestPage(props) {
  const params = useParams();
  const [tutorialOpen, setTutorialOpen] = useState(false);

  const duration = formatDuration(params.duration);

  const calendarDetail = useCalendarDetails();
  const availableTimeSlots = useMultiguestEventDetails();

  useEffect(() => {
    const seenTutorial = localStorage.getItem('seenTutorial');
    if (availableTimeSlots.isSuccess && !seenTutorial) {
      setTutorialOpen(true);
    }
  }, [availableTimeSlots.isSuccess]);

  useEffect(() => {
    if (calendarDetail.isSuccess && availableTimeSlots.isSuccess && duration) {
      pushEvent({ ...events.triggerPageView('Timeslots') });
    }
  }, [calendarDetail?.isSuccess, availableTimeSlots?.isSuccess, duration]);

  if (!duration) {
    return (
      <Error message="予約ページが見つかりません。お手数ですが、もう一度お試しください。" />
    );
  }

  if (calendarDetail.hasError) {
    return <Error message={calendarDetail.message} />;
  }

  if (!calendarDetail.isSuccess) {
    return <Loader />;
  }

  if (availableTimeSlots.hasError) {
    return <Error message={availableTimeSlots.message} />;
  }

  function handleFormSubmit(selected) {
    props.change('selectedHours', selected);
    props.history.push({
      pathname: `/${params.calendar_alias}/multiguest/${params.multiguest_uid}/${duration}min/confirmation`,
    });
  }

  return (
    <>
      <Field name="selectedHours" component="input" hidden />
      <BigCalendar
        duration={duration}
        calendarDetail={calendarDetail}
        useAvailableTimeSlots={useAvailableTimeSlots}
        businessOpeningTime={calendarDetail.businessOpeningTime}
        businessClosingTime={calendarDetail.businessClosingTime}
        isMultiTime
        handleChange={handleFormSubmit}
      />
      <OnboardingModal
        isOpen={tutorialOpen}
        toggleModal={() => {
          localStorage.setItem('seenTutorial', true);
          setTutorialOpen(oldOpen => !oldOpen);
        }}
      />
      <Footer mt={['55px', '18px']} />
    </>
  );
}

MultiguestPage.propTypes = {
  change: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export const Multiguest = reduxForm({
  form: 'MultiguestForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
})(MultiguestPage);
