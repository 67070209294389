import { useIntl } from 'react-intl';

import messages from './i18n/durationUtils';

export default function formatDuration(duration) {
  if (duration == null) return null;

  const parsedDuration = duration.match(/^(?<duration>[0-9]{1,3})min$/);

  return parsedDuration ? parseInt(parsedDuration[1], 10) : null;
}

export function useHeaderFormatDuration(durationString) {
  const intl = useIntl();
  const duration = formatDuration(durationString);

  const hour = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hourString =
    hour > 0 ? `${hour}${intl.formatMessage(messages.hour)}` : '';
  const minuteString =
    minutes > 0 ? `${minutes}${intl.formatMessage(messages.minute)}` : '';

  return `${hourString}${minuteString}`;
}
