import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyPolicy: {
    id: 'confirmation.form.privacyPolicy',
    defaultMessage: 'I agree to <a>Privacy Policy</a>',
  },
  customPrivacyPolicy: {
    id: 'confirmation.form.customPrivacyPolicy',
    defaultMessage:
      'I agree to <a>Privacy Policy</a> of product owner RECEPTIONIST Inc. and <c></c>',
  },
  reselectBtn: {
    id: 'confirmation.form.reselectBtn',
    defaultMessage: 'Go Back',
  },
  submit: {
    id: 'confirmation.form.submitBtn',
    defaultMessage: 'Submit',
  },
  confirmationDetails: {
    id: 'confirmation.form.selectedCandidate.confirmationDetails',
    defaultMessage: 'Candidate date details confirmation',
  },
  selectedCandidateDateTime: {
    id: 'confirmation.form.selectedCandidate.dateTime',
    defaultMessage: 'Selected candidate date and time',
  },
});
