export function pushEvent(event) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...event,
  });
}

export const events = {
  triggerPageView: pagePath => ({
    event: 'page_view',
    page_path: pagePath,
  }),
  changeWeek: value => ({
    event: 'change_week',
    value,
  }),
  changeMonth: value => ({
    event: 'change_month',
    value,
  }),
  calendarIconClick: () => ({
    event: 'click_small_calendar',
  }),
  weekAvailabilitySelected: () => ({
    event: 'jump_to_week',
  }),
  goBackToForm: () => ({
    event: 'click_goBack_form',
  }),
  onClickSchedulingAppLink: href => ({
    event: 'click_link_lp',
    href,
  }),
  onClickLinkHelp: href => ({
    event: 'click_link_help',
    href,
  }),
  onClickCalendarLinkTags: (href, calendar) => ({
    event: 'click_link_calendar',
    href,
    calendar,
  }),
};
