import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { globalActions } from 'constants/globalActions';
import { useGet } from 'utils/api';

export function useCalendarDetails() {
  const params = useParams();
  const calendarDetail = useSelector(state => state.calendarDetail);
  const fetchCalendarDetails = find(globalActions, { name: 'calendarDetail' });
  fetchCalendarDetails.url = `booking_calendars/${params.calendar_alias}?alias=true`;
  useGet(fetchCalendarDetails);
  return calendarDetail;
}
