import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Label } from '@d-lighted/design-system';

import { RadioButton } from '../../../components/forms/RadioButton';
import { Row, Col } from '../../../components/utils/Helper';
import { ErrorUI } from '../../../components/forms/ErrorUI';
import RequiredMark from './RequiredMark';

const InputWrapper = styled.div`
  display: grid;
  width: ${props => props.width};
`;

const InputLabel = styled(Label)`
  margin-bottom: 10px;
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;

export default function RadioButtonCase({ field, validateArray, isRequired }) {
  const radiobuttonLabels = field.bookingCalendarMultipleChoiceOptions;
  const submitFailed = useSelector(state => state.form?.Booking?.submitFailed);
  const error = useSelector(
    state => state.form?.Booking?.submitErrors?.[field.uid],
  );
  return (
    <Row alignItems="center" mb="25px">
      <Col>
        <InputWrapper width="100%">
          <InputLabel>
            {isRequired ? (
              <>
                {field.labelName}
                <RequiredMark />
              </>
            ) : (
              field.labelName
            )}
          </InputLabel>
          <InputDiv>
            {radiobuttonLabels.map((label, index) => (
              <Field
                key={`radio-${field.uid}-${label.choiceTypeName}`}
                component={RadioButton}
                id={label.bookingCalendarCustomFieldId}
                name={field.uid}
                trueFor={label.choiceTypeName}
                validate={validateArray}
                isDisplayError={
                  submitFailed && radiobuttonLabels.length === index + 1
                }
              >
                {label.choiceTypeName}
              </Field>
            ))}
          </InputDiv>
        </InputWrapper>
        {submitFailed && error && <ErrorUI error={error} />}
      </Col>
    </Row>
  );
}

RadioButtonCase.defaultProps = {
  validateArray: [],
  isRequired: false,
};

RadioButtonCase.propTypes = {
  field: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  validateArray: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isRequired: PropTypes.bool,
};
