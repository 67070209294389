import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  :root {
    font-family: 'Noto Sans Japanese', sans-serif;
  }

  * {
    font-family: 'Noto Sans Japanese', sans-serif;
  }

  #root {
    background-color:  #f7f9f9;
    min-height: 100vh;
  }
`;
