import React from 'react';
import { Field } from 'redux-form';
import { InputWithLabel } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../components/utils/Helper';
import RequiredMark from './RequiredMark';

export default function TextFieldCase({ field, validateArray, isRequired }) {
  const { id, uid, labelName } = field;
  return (
    <Row alignItems="center" mb="25px">
      <Col>
        <Field
          component={InputWithLabel}
          id={id}
          validate={validateArray}
          name={uid}
          labelLeft={
            isRequired ? (
              <>
                {labelName}
                <RequiredMark />
              </>
            ) : (
              labelName
            )
          }
          placeholder=""
        />
      </Col>
    </Row>
  );
}

TextFieldCase.defaultProps = {
  validateArray: [],
  isRequired: false,
};

TextFieldCase.propTypes = {
  field: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  validateArray: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isRequired: PropTypes.bool,
};
