import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Label } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../components/forms/Checkbox';
import { Row, Col } from '../../../components/utils/Helper';
import RequiredMark from './RequiredMark';

const InputWrapper = styled.div`
  display: grid;
  width: ${props => props.width};
`;

const InputLabel = styled(Label)`
  margin-bottom: 10px;
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;

export default function CheckBoxCase({ field, validateArray, isRequired }) {
  const checkboxLabels = field.bookingCalendarMultipleChoiceOptions;
  const submitFailed = useSelector(state => state.form?.Booking?.submitFailed);

  return (
    <Row alignItems="center" mb="25px">
      <Col>
        <InputWrapper width="100%">
          <InputLabel>
            {isRequired ? (
              <>
                {field.labelName}
                <RequiredMark />
              </>
            ) : (
              field.labelName
            )}
          </InputLabel>
          <InputDiv>
            {checkboxLabels.map((label, index) => (
              <Field
                key={`check-${field.uid}-${label.choiceTypeName}`}
                component={Checkbox}
                id={`${label.bookingCalendarCustomFieldId}`}
                name={field.uid}
                trueFor={label.choiceTypeName}
                validate={validateArray}
                isDisplayError={
                  submitFailed && checkboxLabels.length === index + 1
                }
              >
                {label.choiceTypeName}
              </Field>
            ))}
          </InputDiv>
        </InputWrapper>
      </Col>
    </Row>
  );
}

CheckBoxCase.defaultProps = {
  validateArray: [],
  isRequired: false,
};

CheckBoxCase.propTypes = {
  field: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  validateArray: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isRequired: PropTypes.bool,
};
