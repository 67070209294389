import React from 'react';
import styled from 'styled-components';
import { isMobileDevice } from 'utils/mobileUtils';
import Step3 from 'assets/img/multiguest_onboarding_step3.png';
import { Heading2 } from 'components/elements/Typography';

const CustomHeading = styled(Heading2)`
  font-weight: bold;
  padding: 50px ${props => (props.isMobile ? '10px' : '85px')} 16px;
`;

const Span = styled.span`
  padding: 0 ${props => (props.isMobile ? '10px' : '85px')};
  text-align: center;
`;

const StepImage = styled.img`
  width: 100%;
  border-radius: 5px 5px 0 0;
`;

export default function OnboardingStep3() {
  const isMobile = isMobileDevice();
  return (
    <>
      <StepImage src={Step3} />
      <CustomHeading isMobile={isMobile}>
        集められた日時の中から、担当者が日時を確定します
      </CustomHeading>
      <Span isMobile={isMobile}>
        担当者が、全員が参加できる日時を探してその中から決めます
      </Span>
    </>
  );
}
