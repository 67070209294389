import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from 'components/utils/Helper';
import { Modal } from '@d-lighted/design-system';
import Close from 'assets/img/Icon_Close.svg';
import { isMobileDevice } from 'utils/mobileUtils';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const Button = styled.button`
  padding: 11px;
  width: ${props => (props.isMobile ? 'auto' : '183px')};
  cursor: pointer;
`;

const ButtonRow = styled(Row)`
  width: 100%;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 12px;
  width: 12px;
  cursor: pointer;
`;

const CustomModal = styled(Modal)`
  position: relative;
`;

const PrevButton = styled(Button)`
  color: ${props => (props.disabled ? 'rgba(20, 44, 43, 0.2)' : '#6e52ff')};
  background-color: white;
  border: ${props =>
    props.isMobile
      ? 'none'
      : `1px solid ${props.disabled ? 'rgba(20, 44, 43, 0.2)' : '#6e52ff'}`};
  border-radius: 5px;
  margin-right: 15px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
`;

const NextButton = styled(Button)`
  color: ${props => (props.isMobile ? '#6e52ff' : 'white')};
  background-color: ${props => (props.isMobile ? 'white' : '#6e52ff')};
  border: none;
  border-radius: 5px;
  font-weight: bold;
`;

const StepIndicator = styled.div`
  background-color: ${props =>
    props.selected ? '#6e52ff' : 'rgba(20, 44, 43, 0.2)'};
  border-radius: 50%;
  margin: ${props => (props.isMobile ? '5px' : '16px 20px')};
  height: 10px;
  width: 10px;
`;

function OnboardingModal({ isOpen, toggleModal }) {
  const [index, setIndex] = useState(0);
  const isMobile = isMobileDevice();

  const navigateToNextOrFinish = () => {
    if (index === 2) {
      toggleModal();
    } else {
      setIndex(oldIndex => oldIndex + 1);
    }
  };

  const navigateToPrevious = () => {
    setIndex(oldIndex => oldIndex - 1);
  };

  const renderStep = () => {
    switch (index) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      default:
        return <Step1 />;
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggleModal}
      onEscapeKeydown={toggleModal}
      width={isMobile ? '92%' : '765px'}
      height={isMobile ? '500px' : '745px'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <CloseIcon src={Close} onClick={toggleModal} />
      <Row margin={0} justifyContent="center" position="relative">
        {renderStep()}
      </Row>
      <ButtonRow justifyContent={isMobile ? 'space-between' : 'center'}>
        <PrevButton
          isMobile={isMobile}
          onClick={navigateToPrevious}
          disabled={index <= 0}
        >
          戻る
        </PrevButton>
        <NextButton isMobile={isMobile} onClick={navigateToNextOrFinish}>
          {index === 2 ? '日時を選ぶ' : '次へ'}
        </NextButton>
      </ButtonRow>
      <Row margin="0 0 10px">
        <StepIndicator isMobile={isMobile} selected={index === 0} />
        <StepIndicator isMobile={isMobile} selected={index === 1} />
        <StepIndicator isMobile={isMobile} selected={index === 2} />
      </Row>
    </CustomModal>
  );
}

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default OnboardingModal;
