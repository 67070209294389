import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import TagManager from 'react-gtm-module';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { BaseCSS } from 'styled-bootstrap-grid';
import { ReceptionistThemeProvider } from '@d-lighted/design-system';

/** Local Imports */
import App from './App';
import { theme } from './constants/Theme';
import { GlobalStyle } from './components/layouts/GlobalStyle';
import { configureReduxStore } from './redux/store';
import LocaleProvider from './providers/LocaleProvider';

const { store, persistor } = configureReduxStore();

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ReceptionistThemeProvider GlobalStyle={GlobalStyle}>
          <LocaleProvider>
            <BaseCSS />
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </LocaleProvider>
        </ReceptionistThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
