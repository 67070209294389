import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { events, pushEvent } from 'utils/GTM';

import { Heading4, LinkText } from '../../../components/elements/Typography';
import Footer from '../../../components/layouts/Footer';
import { Div } from '../../../components/utils/Helper';

function Error({ message: msg, history, location }) {
  const message = msg || ((location || {}).state || {}).message;
  if (message == null) {
    window.location.replace('/');
  } else {
    pushEvent({ ...events.triggerPageView('Error') });
  }

  const back = ((location || {}).state || {}).back || false;

  const goBack = () => history.goBack();

  return (
    <Div bg="#f7f9f9" height="100vh">
      <Div pt="71px" textAlign="center">
        <Heading4 dangerouslySetInnerHTML={{ __html: message }} />
        {back && (
          <Div display="inline-block" textAlign="center" onClick={goBack}>
            <LinkText>戻る</LinkText>
          </Div>
        )}
      </Div>
      <Footer mt="95px" />
    </Div>
  );
}

export default Error;

Error.defaultProps = {
  message: undefined,
  location: undefined,
  history: { goBack: () => {} },
};

Error.propTypes = {
  location: ReactRouterPropTypes.location,
  history: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  message: PropTypes.string,
};
