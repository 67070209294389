import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Label, InputSelect } from '@d-lighted/design-system';

import { Row, Col } from '../../../components/utils/Helper';
import { ErrorUI } from '../../../components/forms/ErrorUI';
import RequiredMark from './RequiredMark';

const InputWrapper = styled.div`
  display: grid;
  width: ${props => props.width};
`;

const InputLabel = styled(Label)`
  margin-bottom: 10px;
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;

export default function DropdownCase({ field, validateArray, isRequired }) {
  const dropdownOptions = [];
  field.bookingCalendarMultipleChoiceOptions.forEach(option => {
    dropdownOptions.push({
      label: option?.choiceTypeName,
      value: option?.choiceTypeName,
    });
  });
  const submitFailed = useSelector(state => state.form?.Booking?.submitFailed);
  const error = useSelector(
    state => state.form?.Booking?.submitErrors?.[field.uid],
  );
  return (
    <Row alignItems="center" mb="25px">
      <Col>
        <InputWrapper width="100%">
          <InputLabel>
            {isRequired ? (
              <>
                {field.labelName}
                <RequiredMark />
              </>
            ) : (
              field.labelName
            )}
          </InputLabel>
          <InputDiv>
            <Field
              id={field.uid}
              key={`dropdown-${field.uid}`}
              component={InputSelect}
              name={field.uid}
              options={dropdownOptions}
              validate={validateArray}
              isDisplayError={submitFailed}
            />
          </InputDiv>
        </InputWrapper>
        {submitFailed && error && <ErrorUI error={error} />}
      </Col>
    </Row>
  );
}

DropdownCase.defaultProps = {
  validateArray: [],
  isRequired: false,
};

DropdownCase.propTypes = {
  field: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  validateArray: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isRequired: PropTypes.bool,
};
