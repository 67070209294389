import { isEqual } from 'lodash';
import humps from 'lodash-humps';

import { moment } from './momentUtils';

const concatUrlQuery = (url, query) => `${url}${query || ''}`;

const reloadTimeStamp = () => moment().toISOString();

export const checkStatus = status => {
  if (status < 300) {
    return { isSuccess: true, hasError: false };
  }
  return { isSuccess: false, hasError: true };
};

const isStateNew = (oldState, newState) => {
  return !isEqual(oldState, newState);
};

const formatAxiosResponse = response => {
  const { data, status, request } = response;
  return {
    ...humps(data),
    status,
    url: request.responseURL,
    ...checkStatus(status),
  };
};

export { concatUrlQuery, reloadTimeStamp, formatAxiosResponse, isStateNew };
