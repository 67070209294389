import * as constants from 'constants/index';

import Confirmation from './pages/Confirmation';
import CompletePage from './pages/Complete';

export const confirmationRoutes = [
  {
    component: Confirmation,
    path: '/:calendar_alias/confirmation',
    exact: true,
    feature: 'confirmation_irregular',
  },
  {
    component: CompletePage,
    path: '/:calendar_alias/completed',
    exact: true,
    feature: 'completed_irregular',
  },
  {
    component: Confirmation,
    path: `/:calendar_alias/:duration${constants.durations}/confirmation`,
    exact: true,
    feature: 'confirmation',
  },
  {
    component: CompletePage,
    path: `/:calendar_alias/:duration${constants.durations}/completed`,
    exact: true,
    feature: 'completed',
  },
];
