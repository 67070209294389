import { createReducer } from '@reduxjs/toolkit';

const INITIAL_STATUS = {
  isSuccess: false,
  hasError: false,
};

function reducerFn(resource) {
  return createReducer(
    resource.initialState
      ? { ...resource.initialState }
      : { ...INITIAL_STATUS },
    {
      [`${resource.name}_get`]: (state, action) => {
        return { ...state, ...action.payload };
      },
      [`${resource.name}_update`]: (state, action) => {
        return { ...action.payload };
      },
      [`${resource.name}_reset`]: () => {
        return {
          ...(resource.initialState || INITIAL_STATUS),
          ...{ reset: true },
        };
      },
    },
  );
}

export function generateReducers(resources) {
  return resources.reduce((reducers, resource) => {
    reducers[resource.name] = reducerFn(resource);
    return reducers;
  }, {});
}
