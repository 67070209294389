import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { globalActions } from 'constants/globalActions';
import { useGet } from 'utils/api';

export function useMultiguestEventDetails() {
  const params = useParams();
  const multiguestEvent = useSelector(state => state.multiguestEvent);
  const fetchMultiguestEvent = find(globalActions, { name: 'multiguestEvent' });
  fetchMultiguestEvent.url = `booking_calendars/${params.calendar_alias}/multiguest/${params.multiguest_uid}?duration=${params.duration}`;
  useGet(fetchMultiguestEvent);
  return multiguestEvent;
}

export function useCalendarDetails() {
  const params = useParams();
  const calendarDetail = useSelector(state => state.calendarDetail);
  const fetchCalendarDetails = find(globalActions, { name: 'calendarDetail' });
  fetchCalendarDetails.url = `booking_calendars/${params.calendar_alias}`;
  useGet(fetchCalendarDetails);
  return calendarDetail;
}
