import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorText } from '@d-lighted/design-system';

const Wrapper = styled.div`
  position: absolute;
`;

export function ErrorUI({ error }) {
  return (
    <Wrapper>
      <ErrorText>{error}</ErrorText>
    </Wrapper>
  );
}

ErrorUI.defaultProps = {
  error: null,
};

ErrorUI.propTypes = {
  error: PropTypes.string,
};
