import { moment } from 'utils/momentUtils';

export function generateCandidateTimeslots(selectedSlots, timezone) {
  const formattedSlots = [];
  selectedSlots.map(slot => {
    formattedSlots.push({
      start_at: moment
        .tz(
          `${slot.year}-${slot.month}-${slot.date} ${slot.time}`,
          'YYYY-MM-DD hh:mm',
          timezone,
        )
        .toISOString(true),
      end_at: moment
        .tz(
          `${slot.year}-${slot.month}-${slot.date} ${slot.nextHour}`,
          'YYYY-MM-DD hh:mm',
          timezone,
        )
        .toISOString(true),
    });
    return formattedSlots;
  });
  return formattedSlots;
}
