import { defineMessages } from 'react-intl';

export default defineMessages({
  minute: {
    id: 'utils.duration.minutes',
    defaultMessage: 'minutes',
  },
  scheduleAdjustment: {
    id: 'layouts.header.scheduleAdjustment',
    defaultMessage: 'Schedule adjustment',
  },
});
