import { defineMessages } from 'react-intl';

export default defineMessages({
  selectDateTime: {
    id: 'booking.bigCalendar.calendarHeader.instruction',
    defaultMessage: 'Please select desired date and time',
  },
  previousWeek: {
    id: 'booking.bigCalendar.calendarHeader.prevBtn',
    defaultMessage: 'Prev. Week',
  },
  nextWeek: {
    id: 'booking.bigCalendar.calendarHeader.nextBtn',
    defaultMessage: 'Next Week',
  },
  participantList: {
    id: 'booking.bigCalendar.calendarHeader.participantsList',
    defaultMessage: 'List of participants',
  },
  customerDate: {
    id: 'booking.bigCalendar.calendarHeader.customerEnterDate',
    defaultMessage: 'Guests who submitted candidate dates',
  },
});
