import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'complete.multiguest.title',
    defaultMessage: 'Candidate dates have been sent to the calendar manager',
  },
  heading: {
    id: 'complete.multiguest.headingText',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Thank you for entering your information. Please wait a few moments until other participants have entered their potential dates and the person in charge will decide on a definite date and time.',
  },
  support: {
    id: 'complete.multiguest.support',
    defaultMessage: '調整アポ Support',
  },
});
