import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Heading2, Heading4 } from 'components/elements/Typography';
import { Div, StyledLink } from 'components/utils/Helper';
import Footer from 'components/layouts/Footer';
import picVoting from 'assets/img/pic_voting.svg';

import messages from './i18n/complete';

const MessageArea = styled.div`
  white-space: pre;
`;

const CompletePage = () => {
  const { completedMessageWebpage } = useSelector(
    state => state.multiguestEvent.bookingCalendarMultiguestScheduledEvent,
  );

  return (
    <>
      <Div textAlign="center" mt="30px">
        <Heading2 margin="10px">
          <FormattedMessage {...messages.title} />
        </Heading2>
        <MessageArea>
          <Heading4 margin="10px">
            {completedMessageWebpage || (
              <FormattedMessage {...messages.heading} />
            )}
          </Heading4>
        </MessageArea>
        <Div display="flex" justifyContent="center" mt="60px" mb="40px">
          <img width="200px" height="150px" src={picVoting} alt="Completed" />
        </Div>
      </Div>
      <Div display="flex" justifyContent="center">
        <StyledLink
          mt="20px"
          color="#6e52ff"
          href="https://scheduling.receptionist.jp/"
          onClick={() => {}}
        >
          <FormattedMessage {...messages.support} />
        </StyledLink>
      </Div>
      <Footer mt="45px" />
    </>
  );
};

export default CompletePage;

CompletePage.propTypes = {};
