import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as styledSystem from 'styled-system';

import navigationLogo from '../../assets/img/Icons-Navigation-Logo.svg';

const FooterWrapper = styled.a`
  display: block;
  height: 48px;
  font-size: 10px;
  color: #314143;
  text-align: center !important;
  text-decoration: none;
  ${styledSystem.margin}
`;

const FooterImage = styled.img`
  width: 73px;
  height: 16px;
  margin: 0 12px 0 0;
  opacity: 0.5;
  vertical-align: text-bottom !important;
`;

const FooterText = styled.span`
  width: 144px;
  height: 15px;
  margin: 0.8px 0 0.2px;
  opacity: 0.7;
  font-family: NotoSansJP;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #314143;
`;

function Footer({ mt }) {
  return (
    <FooterWrapper
      mt={mt}
      href="https://scheduling.receptionist.jp/"
      target="_blank"
    >
      <FooterImage
        className="footer_page_image align-text-bottom"
        src={navigationLogo}
        alt="日程調整ツール 調整アポ"
      />
      <FooterText>Powered by RECEPTIONIST Inc.</FooterText>
    </FooterWrapper>
  );
}

Footer.defaultProps = {
  mt: '24px',
};

Footer.propTypes = {
  mt: PropTypes.oneOfType([PropTypes.array, PropTypes.string]), // eslint-disable-line react/forbid-prop-types
};

export default Footer;
