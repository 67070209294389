import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontWeight,
  width,
  minWidth,
  height,
  fontSize,
  color,
  space,
  borders,
  borderRadius,
  position,
  shadow,
  textAlign,
} from 'styled-system';

const Button = styled.button`
  padding: ${props =>
    props.isIconSvgPresent || props.isIconPresent
      ? '9px 20px 12px'
      : '9px 0 12px'};
  box-sizing: border-box;
  cursor: pointer;
  padding-left: ${props => (props.isIconPresent ? '20px' : 0)};
  &:before {
    position: absolute;
    content: '+';
    display: ${props => (props.isIconPresent ? 'block' : 'none')};
    left: 20px;
    top: 0px;
    font-size: 30px;
    font-weight: 100;
  }
  transition: background-color 0.3s ease, color 0.3s ease;
  ${position}
  ${width}
  ${minWidth}
  ${height}
  ${fontSize}
  ${color}
  ${space}
  ${borders}
  ${borderRadius}
  ${textAlign}
  ${fontWeight}
  ${shadow}
  &:hover {
    background-color: #259590;
  }
  ${props =>
    props.outlined &&
    `
    background-color: white;
    color: ${props.outlinedColor || '#00bbb5'};
    border: 1px solid ${props.outlinedColor || '#00bbb5'};
    &:hover {
      background-color: #E6F4EF;
    }
  `};
  ${props =>
    props.invertOnHover &&
    `
    overflow: hidden;
    & > div {
      display: inline;
      transition: opacity 0.5s 0.1s ease-out, margin 0.5s 0.1s ease-out, color 0.2s ease-out;
    }
    & > .hidden {
      position: absolute;
      min-width: max-content;
      opacity: 0;
      margin: auto -35% auto auto;

      &::before {
        content: '-';
        margin-right: 5px;
      }
    }
    &:hover {
      background-color: ${props.outlinedColor || '#00bbb5'};
      color: white;
    }
    &:not(:disabled):hover > .shown {
      margin-left: -64px;
      margin-right: 5px;
    }
    @media screen and (min-width: 576px) and (max-width: 992px){
      &:not(:disabled) > .shown {
        display: block;
      }
      &:not(:disabled):hover > .shown {
        margin: -24px 0 0 0;
      }
      & > .hidden {
        display: block;
        width: 100%;

        &::before {
          content: none;
          margin: 0;
        }
      }
    }
    @media screen and (min-width: 992px) {
      &:not(:disabled):hover > .shown {
        margin-left: -64px;
        margin-right: 5px;
      }
    }
    &:not(:disabled):hover > .hidden {
      opacity: 1;
    }
    &:disabled {
      cursor: no-drop;
      color: rgba(20, 44, 43, 0.2) !important;
      border: solid 2px rgb(210, 233, 231, 0.65) !important;
      background: rgb(255, 255, 255, 0.65) !important;
      box-shadow: none;
    }
  `};
  &:focus {
    outline: none;
  }
`;

Button.defaultProps = {
  borderRadius: '5px',
  position: 'relative',
  height: 50,
  color: 'white',
  bg: '#00bbb5',
  fontWeight: 'bold',
  fontSize: '16px',
  textAlign: 'center',
  width: '250px',
  border: 'none',
};

Button.propTypes = {
  ...position.propTypes,
  ...width.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...space.propTypes,
  ...borders.propTypes,
  ...textAlign.propTypes,
  ...fontWeight.propTypes,
  border: PropTypes.string,
  submitFailed: PropTypes.bool,
};

export default Button;
