import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import { useLocale, LOCALES } from 'hooks/useLocale';
import localeJA from 'locales/ja.json';
import localeEN from 'locales/en.json';

const messages = {};
messages[LOCALES.JA] = localeJA;
messages[LOCALES.EN] = localeEN;

function LocaleProvider({ children }) {
  const [locale, setLocale] = useLocale();
  const [unloaded, setUnloaded] = useState(true);
  const browserLang = navigator.language;

  if (unloaded && browserLang) {
    if (browserLang.toLowerCase().includes('ja')) setLocale(LOCALES.JA);
    else setLocale(LOCALES.EN);
    setUnloaded(false);
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleProvider;
