import * as constants from 'constants/index';

import { Booking } from './pages/Booking';

export const bookingRoutes = [
  {
    component: Booking,
    path: '/:calendar_alias',
    exact: true,
    feature: 'booking_irregular',
  },
  {
    component: Booking,
    path: `/:calendar_alias/:duration${constants.durations}`,
    exact: true,
    feature: 'booking',
  },
];
