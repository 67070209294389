/* eslint-disable react-hooks/exhaustive-deps, no-underscore-dangle */
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

/** Utils */
import { createAction } from '@reduxjs/toolkit';
import {
  concatUrlQuery,
  reloadTimeStamp,
  formatAxiosResponse,
  isStateNew,
} from './apiUtils';

/** Axios instant are initialized with default configuration. */
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.post['Content-Type'] = 'application/json';

/** Interceptions of request in development mode. */
if (process.env.NODE_ENV === 'development') {
  axios.interceptors.request.use(request => {
    return request;
  });

  axios.interceptors.response.use(response => {
    return response;
  });
}

/** Function that create header request */
function createHeader(token) {
  if (token) {
    return { Accept: 'application/json', Authorization: `Bearer ${token}` };
  }
  return { Accept: 'application/json' };
}

/** Function that trigger GET request */
async function _get(url, token) {
  return axios.get(url, createHeader(token)).catch(e => e.response);
}

/** Function that trigger POST request */
async function _post(url, body, token) {
  return axios.post(url, body, createHeader(token)).catch(e => e.response);
}

/** Function that trigger Put request */
async function _put(url, body, token) {
  return axios.put(url, body, createHeader(token)).catch(e => e);
}

/** Function that trigger Delete request */
async function _delete(url, token) {
  return axios.delete(url, createHeader(token)).catch(e => e);
}

function useGet(request) {
  const token = undefined;
  const [response, setResponse] = useState(undefined);
  const [query, setQuery] = useState(request.query);
  const [reload, setReload] = useState(reloadTimeStamp());
  const dispatch = useDispatch();
  const getAction = createAction(`${request.name}_update`);
  const resetAction = createAction(`${request.name}_reset`);
  const loadingAction = createAction('loading_update');

  useEffect(() => {
    if (request.reset) dispatch(resetAction({}));
    dispatch(loadingAction({ loading: true }));
    _get(concatUrlQuery(request.url, query), createHeader(token)).then(
      requestResponse => {
        const formattedResponse = formatAxiosResponse(requestResponse);
        dispatch(loadingAction({ loading: false }));
        setResponse(formattedResponse);
        dispatch(getAction(formattedResponse));
      },
    );
  }, [dispatch, query, request.name, request.url, token, reload]);
  return {
    response,
    handle: { setQuery, setReload: () => setReload(reloadTimeStamp()) },
  };
}

function usePost({ url, body, ...request }) {
  const token = undefined;
  const [response, setResponse] = useState(undefined);
  const initialState = useSelector(state => state[request.name]);
  const dispatch = useDispatch();
  const postAction = createAction(`${request.name}_post`);
  useEffect(() => {
    _post(url, body, createHeader(token)).then(requestResponse => {
      const formattedResponse = formatAxiosResponse(requestResponse);
      if (isStateNew(initialState, formattedResponse)) {
        dispatch(postAction(formattedResponse));
        setResponse(formattedResponse);
      }
      return 0;
    });
  }, []);
  return { response };
}

function usePut({ url, body, ...request }) {
  const token = undefined;
  const [response, setResponse] = useState(undefined);
  const initialState = useSelector(state => state[request.name]);
  const dispatch = useDispatch();
  const putAction = createAction(`${request.name}_put`);
  useEffect(() => {
    _put(url, body, createHeader(token)).then(requestResponse => {
      const formattedResponse = formatAxiosResponse(requestResponse);
      if (isStateNew(initialState, formattedResponse)) {
        dispatch(putAction(formattedResponse));
        setResponse(formattedResponse);
      }
      return 0;
    });
  }, []);
  return { response };
}

function useDelete(request) {
  const token = undefined;
  const [response, setResponse] = useState(undefined);
  const initialState = useSelector(state => state[request.name]);
  const dispatch = useDispatch();
  const deleteAction = createAction(`${request.name}_delete`);
  useEffect(() => {
    _delete(
      concatUrlQuery(request.url, request.query),
      createHeader(token),
    ).then(requestResponse => {
      const formattedResponse = formatAxiosResponse(requestResponse);
      if (isStateNew(initialState, formattedResponse)) {
        dispatch(deleteAction(formattedResponse));
        setResponse(formattedResponse);
      }
      return 0;
    });
  }, []);
  return { response };
}
export { useGet, usePost, usePut, useDelete, _post, _get };
