import Error from './pages/Error';
import HomeError from './pages/HomeError';
// import CalendarError from './pages/CalendarError';

export const errorRoutes = [
  {
    component: HomeError,
    path: '/',
    exact: true,
    feature: 'error',
    gauid: 'Error',
  },
  {
    component: Error,
    path: '/error',
    exact: true,
    feature: 'error',
    gauid: 'Error',
  },
];
