import React from 'react';
import styled from 'styled-components';
import { isMobileDevice } from 'utils/mobileUtils';
import Step2 from 'assets/img/multiguest_onboarding_step2.png';
import { Heading2 } from 'components/elements/Typography';

const CustomHeading = styled(Heading2)`
  font-weight: bold;
  padding: 50px ${props => (props.isMobile ? '10px' : '85px')} 16px;
`;

const Span = styled.span`
  padding: 0 ${props => (props.isMobile ? '10px' : '85px')};
  text-align: center;
`;

const StepImage = styled.img`
  width: 100%;
  border-radius: 5px 5px 0 0;
`;

export default function OnboardingStep2() {
  const isMobile = isMobileDevice();
  return (
    <>
      <StepImage src={Step2} />
      <CustomHeading isMobile={isMobile}>
        あなたが空いている日時をできるだけ多く選択してください
      </CustomHeading>
      <Span isMobile={isMobile}>誰がその日時に参加できるのか確認できます</Span>
    </>
  );
}
