import { defineMessages } from 'react-intl';

export default defineMessages({
  formHeading: {
    id: 'confirmation.form.heading',
    defaultMessage: 'Booking Confirmation',
  },
  formHeadingIrregular: {
    id: 'confirmation.form.headingIrregular',
    defaultMessage: 'Registration Confirmation',
  },
  dateTime: {
    id: 'confirmation.form.dateTimeCard.label',
    defaultMessage: 'Date & Time',
  },
  privacyPolicy: {
    id: 'confirmation.form.privacyPolicy',
    defaultMessage: 'I agree to <a>Privacy Policy</a>',
  },
  customPrivacyPolicy: {
    id: 'confirmation.form.customPrivacyPolicy',
    defaultMessage:
      'I agree to <a>Privacy Policy</a> of product owner RECEPTIONIST Inc. and <c></c>',
  },
  reselectBtn: {
    id: 'confirmation.form.reselectBtn',
    defaultMessage: 'Go Back',
  },
  confirmBtn: {
    id: 'confirmation.form.confirmBtn',
    defaultMessage: 'Confirm Booking',
  },
  confirmBtnIrregular: {
    id: 'confirmation.form.confirmBtnIrregular',
    defaultMessage: 'Confirm Registration',
  },
});
