import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'complete.heading',
    defaultMessage: 'The booking has been confirmed',
  },
  titleIrregular: {
    id: 'complete.headingIrregular',
    defaultMessage: 'Registration has been completed',
  },
  googleBtn: {
    id: 'complete.googleBtn',
    defaultMessage: 'Add to Google Calendar',
  },
  outlookBtn: {
    id: 'complete.outlookBtn',
    defaultMessage: 'Add to Outlook Calendar',
  },
  notice: {
    id: 'complete.webMsg',
    defaultMessage: '* Only compatible with Web browser version',
  },
  scheduleLink: {
    id: 'complete.schedulingLink',
    defaultMessage: 'Did you like the product? Find out more about "調整アポ"',
  },
});
