import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import 'moment/locale/ja';

const moment = extendMoment(Moment);

moment.tz.setDefault();

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

function getThisWeek(date, withExtraDay) {
  const range = moment.rangeFromInterval(
    'days',
    withExtraDay ? 7 : 6,
    date.startOf('week'),
  );

  const week = Array.from(range.by('day'));

  return {
    week,
    selectedDate: moment(date),
    range,
  };
}

function getThisLastWeek(date) {
  return date.subtract(1, 'week').startOf('week');
}

function getNextWeek() {
  return function nextWeek(date, withExtraDay) {
    date.add(1, 'week');

    const range = moment.rangeFromInterval(
      'days',
      withExtraDay ? 7 : 6,
      date.startOf('week'),
    );

    const week = Array.from(range.by('day'));

    return {
      week,
      selectedDate: date,
    };
  };
}

function getPreviousWeek() {
  return function previousWeek(date, withExtraDay) {
    date.subtract(1, 'week');

    const range = moment.rangeFromInterval(
      'days',
      withExtraDay ? 7 : 6,
      date.startOf('week'),
    );

    const week = Array.from(range.by('day'));

    return {
      week,
      selectedDate: date,
    };
  };
}

function getHoursOfTheDay(
  businessOpeningTime,
  businessClosingTime,
  duration,
  selectedTimezone,
) {
  const today = moment().format('YYYY-MM-DD');
  let time = moment(`${today}T${businessOpeningTime}+09:00`).tz(
    selectedTimezone,
  );
  const data = [];
  let hour = time.format('HH');
  let minute = time.minutes();
  minute = minute < 10 ? `0${minute}` : minute;
  let fullTime = `${hour}:${minute}`;

  while (
    time.diff(
      moment(`${today}T${businessClosingTime}+09:00`).tz(selectedTimezone),
    ) < 0
  ) {
    data.push(fullTime);
    time = time.add(duration, 'm');
    hour = time.format('HH');
    minute = time.minutes();
    minute = minute < 10 ? `0${minute}` : minute;
    fullTime = `${hour}:${minute}`;
  }
  return data.sort();
}

function isDateEarlier(date1, date2) {
  const difference = date1.startOf('day').diff(date2.startOf('day'));
  if (difference < 0) {
    return 'earlier';
  }
  return difference > 0 ? 'later' : 'equal';
}

function isTimeEarlier(time1, time2) {
  const difference = time1.diff(time2);
  if (difference < 0) {
    return 'earlier';
  }
  return difference > 0 ? 'later' : 'equal';
}

function getJapaneseDay(day) {
  moment.locale('ja');
  const weekdays = moment.weekdaysShort();
  moment.locale('en');
  return weekdays[day];
}

function getEnglishDay(day) {
  const weekdays = moment.weekdaysShort();
  return weekdays[day];
}

function calculateAvailableTime(timeSlots, timezone) {
  let time = '';
  const m = timeSlots.reduce((obj, item) => {
    if (item && item.length) {
      let date = moment(item[0].startTime).format('MM-DD-yyyy');
      item.map(({ startTime }) => {
        if (isTimeEarlier(moment(startTime), moment()) === 'later') {
          [date, time] = moment(startTime)
            .tz(timezone)
            .format('MM-DD-yyyy HH:mm')
            .split(' ');
          if (date in obj) {
            obj[date].push(time);
          } else {
            obj[date] = [time];
          }
        }
        return null;
      });
    }
    return obj;
  }, {});
  return m;
}

const getCalendarOptions = options => {
  return {
    today: moment(),
    currentDate: moment(),
    selectedDate: moment(),
    calculateAvailableTime,
    duration: options.duration,
    lastWeek: getThisLastWeek(moment()),
    currentWeek: getThisWeek(options.currentDate),
    businessOpeningTime: options.businessOpeningTime,
    businessClosingTime: options.businessClosingTime,
    getThisWeek,
    getNextWeek: getNextWeek(),
    getPreviousWeek: getPreviousWeek(),
    getJapaneseDay,
    getEnglishDay,
    isTimeEarlier,
    isDateEarlier,
    getHoursOfTheDay: getHoursOfTheDay(
      options.businessOpeningTime,
      options.businessClosingTime,
      options.duration,
      options.selectedTimezone,
    ),
  };
};

function formatTimezoneLabel(time) {
  if (time < 0 && time > -10) {
    return time.replace('-', '-0');
  }
  return time.padStart(2, '0');
}

function generateTimezoneLabel(hour, timezone) {
  const isDaylightSavingTime = moment().tz(timezone).isDST();
  const label = isDaylightSavingTime
    ? formatTimezoneLabel(`${parseInt(hour, 10) + 1}`)
    : hour;
  return label === '00' ? '+00' : label;
}

function getMinimalTimezoneSet() {
  return [
    {
      offset: '-11:00',
      label: `UTC${generateTimezoneLabel(
        '-11',
        'Pacific/Pago_Pago',
      )}:00 Pago Pago`,
      tzCode: 'Pacific/Pago_Pago',
    },
    {
      offset: '-10:00',
      label: `UTC${generateTimezoneLabel('-10', 'Pacific/Tahiti')}:00 Tahiti`,
      tzCode: 'Pacific/Tahiti',
    },
    {
      offset: '-09:00',
      label: `UTC${generateTimezoneLabel(
        '-09',
        'America/Anchorage',
      )}:00 Alaska Time`,
      tzCode: 'America/Anchorage',
    },
    {
      offset: '-08:00',
      label: `UTC${generateTimezoneLabel(
        '-08',
        'America/Los_Angeles',
      )}:00 Pacific Time`,
      tzCode: 'America/Los_Angeles',
    },
    {
      offset: '-07:00',
      label: `UTC${generateTimezoneLabel(
        '-07',
        'America/Denver',
      )}:00 Mountain Time`,
      tzCode: 'America/Denver',
    },
    {
      offset: '-06:00',
      label: `UTC${generateTimezoneLabel(
        '-06',
        'America/Chicago',
      )}:00 Central Time`,
      tzCode: 'America/Chicago',
    },
    {
      offset: '-05:00',
      label: `UTC${generateTimezoneLabel(
        '-05',
        'America/New_York',
      )}:00 Eastern Time`,
      tzCode: 'America/New_York',
    },
    {
      offset: '-05:00',
      label: `UTC${generateTimezoneLabel('-05', 'America/Toronto')}:00 Toronto`,
      tzCode: 'America/Toronto',
    },
    {
      offset: '-04:00',
      label: `UTC${generateTimezoneLabel(
        '-04',
        'America/Halifax',
      )}:00 Atlantic Time - Halifax`,
      tzCode: 'America/Halifax',
    },
    {
      offset: '-03:00',
      label: `UTC${generateTimezoneLabel(
        '-03',
        'America/Sao_Paulo',
      )}:00 Sao Paulo`,
      tzCode: 'America/Sao_Paulo',
    },
    {
      offset: '-03:00',
      label: `UTC${generateTimezoneLabel('-03', 'America/Godthab')}:00 Nuuk`,
      tzCode: 'America/Godthab',
    },
    {
      offset: '-01:00',
      label: `UTC${generateTimezoneLabel('-01', 'Atlantic/Azores')}:00 Azores`,
      tzCode: 'Atlantic/Azores',
    },
    {
      offset: '+00:00',
      label: `UTC+${generateTimezoneLabel('00', 'Europe/London')}:00 London`,
      tzCode: 'Europe/London',
    },
    {
      offset: '+01:00',
      label: `UTC+${generateTimezoneLabel('01', 'Europe/Berlin')}:00 Berlin`,
      tzCode: 'Europe/Berlin',
    },
    {
      offset: '+02:00',
      label: `UTC+${generateTimezoneLabel(
        '02',
        'Europe/Helsinki',
      )}:00 Helsinki`,
      tzCode: 'Europe/Helsinki',
    },
    {
      offset: '+03:00',
      label: `UTC+${generateTimezoneLabel(
        '03',
        'Europe/Istanbul',
      )}:00 Istanbul`,
      tzCode: 'Europe/Istanbul',
    },
    {
      offset: '+04:00',
      label: `UTC+${generateTimezoneLabel('04', 'Asia/Dubai')}:00 Dubai`,
      tzCode: 'Asia/Dubai',
    },
    {
      offset: '+04:30',
      label: `UTC+${generateTimezoneLabel('04', 'Asia/Kabul')}:30 Kabul`,
      tzCode: 'Asia/Kabul',
    },
    {
      offset: '+05:00',
      label: `UTC+${generateTimezoneLabel(
        '05',
        'Indian/Maldives',
      )}:00 Maldives`,
      tzCode: 'Indian/Maldives',
    },
    {
      offset: '+05:30',
      label: `UTC+${generateTimezoneLabel(
        '05',
        'Asia/Calcutta',
      )}:30 India Standard Time`,
      tzCode: 'Asia/Calcutta',
    },
    {
      offset: '+05:45',
      label: `UTC+${generateTimezoneLabel(
        '05',
        'Asia/Kathmandu',
      )}:45 Kathmandu`,
      tzCode: 'Asia/Kathmandu',
    },
    {
      offset: '+06:00',
      label: `UTC+${generateTimezoneLabel('06', 'Asia/Dhaka')}:00 Dhaka`,
      tzCode: 'Asia/Dhaka',
    },
    {
      offset: '+06:30',
      label: `UTC+${generateTimezoneLabel('06', 'Indian/Cocos')}:30 Cocos`,
      tzCode: 'Indian/Cocos',
    },
    {
      offset: '+07:00',
      label: `UTC+${generateTimezoneLabel('07', 'Asia/Bangkok')}:00 Bangkok`,
      tzCode: 'Asia/Bangkok',
    },
    {
      offset: '+08:00',
      label: `UTC+${generateTimezoneLabel(
        '08',
        'Asia/Hong_Kong',
      )}:00 Hong Kong`,
      tzCode: 'Asia/Hong_Kong',
    },
    {
      offset: '+09:00',
      label: `UTC+${generateTimezoneLabel('09', 'Asia/Tokyo')}:00 Tokyo`,
      tzCode: 'Asia/Tokyo',
    },
    {
      offset: '+09:30',
      label: `UTC+${generateTimezoneLabel(
        '09',
        'Australia/Adelaide',
      )}:30 Central Time - Adelaide`,
      tzCode: 'Australia/Adelaide',
    },
    {
      offset: '+10:00',
      label: `UTC+${generateTimezoneLabel(
        '10',
        'Australia/Sydney',
      )}:00 Eastern Time - Melbourne, Sydney`,
      tzCode: 'Australia/Sydney',
    },
    {
      offset: '+11:00',
      label: `UTC+${generateTimezoneLabel('11', 'Asia/Magadan')}:00 Magadan`,
      tzCode: 'Asia/Magadan',
    },
    {
      offset: '+12:00',
      label: `UTC+${generateTimezoneLabel(
        '12',
        'Pacific/Auckland',
      )}:00 Auckland`,
      tzCode: 'Pacific/Auckland',
    },
    {
      offset: '+13:00',
      label: `UTC+${generateTimezoneLabel('13', 'Pacific/Tongatapu')}:00 Tonga`,
      tzCode: 'Pacific/Tongatapu',
    },
    {
      offset: '+14:00',
      label: `UTC+${generateTimezoneLabel(
        '14',
        'Pacific/Kiritimati',
      )}:00 Kiritimati`,
      tzCode: 'Pacific/Kiritimati',
    },
  ];
}

function getDefaultTimezone() {
  return moment.tz.guess(true);
}

function formatTimezone() {
  return `UTC${moment().format('zZ')} ${getDefaultTimezone()}`;
}

export {
  getThisWeek,
  getPreviousWeek,
  getNextWeek,
  getHoursOfTheDay,
  isDateEarlier,
  isTimeEarlier,
  getJapaneseDay,
  getEnglishDay,
  moment,
  getCalendarOptions,
  getMinimalTimezoneSet,
  getDefaultTimezone,
  formatTimezone,
  getThisLastWeek,
};
