import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Label } from '@d-lighted/design-system';

import { ErrorUI } from './ErrorUI';

const Wrapper = styled.div`
  display: table;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  padding-bottom: 10px;
`;

const checkedStyles = css`
  border: 2px solid #00bbb5;
  background: #00bbb5;
`;

const uncheckedStyles = css`
  border: 2px solid #d2e9e7;
  background: transparent;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  align-self: center;
  min-width: 16px;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  ${props => (props.checked ? checkedStyles : uncheckedStyles)};
  transition: all 150ms;
  margin-right: 10px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const CheckboxLabel = styled(Label)`
  cursor: pointer;
`;

const Checkbox = ({
  className,
  children,
  trueFor,
  meta,
  useArrayValue,
  labelColor,
  isDisplayError,
  ...props
}) => {
  const { onChange, value } = props.input;

  function handleChange() {
    if (useArrayValue) {
      if (value.includes(trueFor)) {
        onChange(value.filter(item => `${item}` !== `${trueFor}`));
      } else {
        onChange([...value, trueFor]);
      }
    } else {
      onChange(!value);
    }
  }
  return (
    <>
      <Wrapper>
        <CheckboxContainer className={className} onClick={handleChange}>
          <HiddenCheckbox
            checked={useArrayValue ? value.includes(trueFor) : value}
            {...props}
          />
          <StyledCheckbox
            checked={useArrayValue ? value.includes(trueFor) : value}
          >
            <Icon viewBox="0 0 25 25">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          <CheckboxLabel color={labelColor}>{children}</CheckboxLabel>
        </CheckboxContainer>
      </Wrapper>
      {isDisplayError && <ErrorUI {...meta} />}
    </>
  );
};

Checkbox.defaultProps = {
  className: '',
  trueFor: 30,
  onChange: () => {},
  meta: { error: undefined, touched: undefined },
  input: {
    onChange: () => {},
    value: [],
  },
  useArrayValue: true,
  labelColor: '#314143',
  children: undefined,
  isDisplayError: false,
};

Checkbox.propTypes = {
  className: PropTypes.string,
  trueFor: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  useArrayValue: PropTypes.bool,
  labelColor: PropTypes.string,
  children: PropTypes.node,
  isDisplayError: PropTypes.bool,
};

export { Checkbox };
