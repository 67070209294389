import styled from 'styled-components';
import { color, margin, width, textAlign, typography } from 'styled-system';

export const Heading1 = styled.div`
  ${typography}
  ${color}
  ${margin}
`;

Heading1.defaultProps = {
  fontSize: '11px',
  fontWeight: '500',
  color: '#314143',
  letterSpacing: '0.55px',
};

export const Heading2 = styled.div`
  ${typography}
  ${color}
  ${margin}
  ${width}
  ${textAlign}
`;

Heading2.defaultProps = {
  margin: 0,
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '1.45',
  letterSpacing: '1px',
  color: '#314143',
};

export const Heading3 = styled.p`
  ${typography}
  ${color}
  ${margin}
`;

Heading3.defaultProps = {
  fontSize: '15px',
  lineHeight: 1.46,
  color: '#68878d',
};

export const Heading4 = styled.p`
  ${typography}
  ${color}
  ${margin}
`;

Heading4.defaultProps = {
  fontSize: '15px',
  color: '#314143',
  letterSpacing: '0.1px',
  lineHeight: 1.6,
};

export const Heading5 = styled.p`
  ${typography}
  ${color}
  ${margin}
`;

Heading5.defaultProps = {
  fontSize: '28px',
  color: '#314143',
  fontWeight: 500,
  lineHeight: 1.6,
};

export const Heading6 = styled.p`
  ${typography}
  ${color}
  ${margin}
`;

Heading6.defaultProps = {
  fontSize: '13px',
  color: '#314143',
  fontWeight: 500,
  lineHeight: 1.46,
};

export const LinkText = styled.p`
  ${typography}
  ${color}
  ${margin}
  cursor: pointer;
`;

LinkText.defaultProps = {
  fontSize: '16px',
  color: '#00bbb5',
};
