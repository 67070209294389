import { defineMessages } from 'react-intl';

export default defineMessages({
  minute: {
    id: 'utils.duration.minutes',
    defaultMessage: 'minutes',
  },
  hour: {
    id: 'utils.duration.hour',
    defaultMessage: 'hour',
  },
});
