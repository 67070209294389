// import React from 'react';

// import { Heading4 } from '../../../components/elements/Typography';
// import Footer from '../../../components/layouts/Footer';
// import { Div } from '../../../components/utils/Helper';

export default function HomeError() {
  window.location.replace('https://scheduling.receptionist.jp/');
  // return (
  //   <Div bg="#f7f9f9" height="100vh">
  //     <Div pt="71px" textAlign="center">
  //       <Heading4>正しい予約ページURLを指定してください。</Heading4>
  //     </Div>
  //     <Footer mt="95px" />
  //   </Div>
  // );
}
