export const FormatCustomField = ({ fieldType, uid, labelName, value }) => {
  if (fieldType !== 'text_area') {
    if (fieldType === 'date') {
      return {
        booking_calendar_custom_field_uid: uid,
        label_name: labelName,
        input_date_value: value,
      };
    }
    return {
      booking_calendar_custom_field_uid: uid,
      label_name: labelName,
      input_value: value,
    };
  }
  return {
    booking_calendar_custom_field_uid: uid,
    label_name: labelName,
    input_text_area_value: value,
  };
};
