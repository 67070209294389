import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../components/utils/Helper';
import RequiredMark from './RequiredMark';
import SmallCalendar from './SmallCalendar';

export default function DateCase({ field, validateArray, isRequired }) {
  const { id, uid, labelName } = field;
  return (
    <Row alignItems="center" mb="25px">
      <Col>
        <Field
          component={SmallCalendar}
          showActionButton={false}
          id={id}
          validate={validateArray}
          name={uid}
          labelLeft={
            isRequired ? (
              <>
                {labelName}
                <RequiredMark />
              </>
            ) : (
              labelName
            )
          }
          placeholder=""
        />
      </Col>
    </Row>
  );
}

DateCase.defaultProps = {
  validateArray: [],
  isRequired: false,
};

DateCase.propTypes = {
  field: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  validateArray: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isRequired: PropTypes.bool,
};
