import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { DatePicker, Label } from '@d-lighted/design-system';
import { ErrorUI } from 'components/forms/ErrorUI';
import { useLocale } from 'hooks/useLocale';

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropDownContent = styled.div`
  display: ${props => (props.showDropdown ? 'block' : 'none')};
  position: absolute;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translate(50%, -100%);
  top: 10px;
  right: 50%;

  &::after {
    content: '';
    width: 25px;
    height: 25px;
    background: white;
    position: absolute;
    right: 20%;
    top: 95%;
    border-radius: 5px;
    transform: rotate(45deg);
    box-shadow: 10px 10px 15px -2px rgb(0 0 0 / 20%);
  }
`;

const DateLabel = styled(Label)`
  cursor: pointer;
  display: block;
  color: #68878d;
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 10px;
`;

const DateText = styled(Label)`
  cursor: pointer;
  display: block;
  color: #314143;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  padding-left: 0;
`;

const DatePickerButton = styled.div`
  cursor: pointer;
  padding: 5px 15px;
  border: 1px solid #d2e9e7;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: white;
  width: 100%;
  height: 50px;

  ${props =>
    props.focused &&
    `
   border: solid 1px #00bbb5;
   box-shadow: 1px 1px 5px #00bbb5, -1px -1px 5px #00bbb5;
    `}

  &:hover {
    color: red;
  }
`;
const SmallCalendar = ({
  id,
  placeholder,
  input,
  labelLeft,
  minDate,
  maxDate,
  meta: { touched, error },
}) => {
  const [calendar, showCalendar] = useState(false);
  const [focused, changedFocused] = useState(false);
  const [date, setDate] = useState(
    input.value ? moment(input.value).toDate() : moment().toDate(),
  );
  const [locale] = useLocale();

  const handleClickOutside = event => {
    const targetElement = event.target;
    if (
      !targetElement.matches(`#custom_field_${id}`) &&
      !targetElement.parentElement?.matches(`#custom_field_${id}`) &&
      !targetElement.parentElement?.closest('.dropdown-content')
    ) {
      showCalendar(false);
      changedFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateChanged = newValue => {
    showCalendar(false);
    changedFocused(false);

    input.onChange(moment(newValue).format('YYYY-MM-DD'));
    setDate(newValue);
  };

  const onDatePickerClicked = () => {
    showCalendar(!calendar);
    changedFocused(!focused);
  };
  return (
    <DropdownWrapper>
      <DateLabel>{labelLeft}</DateLabel>
      <DatePickerButton
        id={`custom_field_${id}`}
        onClick={onDatePickerClicked}
        focused={focused}
      >
        <DateText>
          {input.value
            ? moment(input.value).format('YYYY/MM/DD')
            : placeholder || ''}
        </DateText>
      </DatePickerButton>
      <DropDownContent className="dropdown-content" showDropdown={calendar}>
        <DatePicker
          style={{ width: 'auto' }}
          highlightWeek={false}
          value={date}
          width="320px"
          selectPast
          onChange={onDateChanged}
          showActionButton={false}
          minDate={
            minDate != null ? moment(minDate).add(1, 'day').toDate() : null
          }
          maxDate={
            maxDate != null ? moment(maxDate).subtract(1, 'day').toDate() : null
          }
          id={`custom_field_${id}`}
          locale={locale}
        />
      </DropDownContent>
      {touched && error && <ErrorUI error={error} />}
    </DropdownWrapper>
  );
};

SmallCalendar.defaultProps = {
  minDate: null,
  maxDate: null,
};

SmallCalendar.propTypes = {
  id: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  labelLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default SmallCalendar;
