import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useParams } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { events, pushEvent } from 'utils/GTM';
import { formatTimezone, getDefaultTimezone } from 'utils/momentUtils';
import { createAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { useCalendarDetails } from 'views/booking/utils/serverUtils';
import { useAvailableTimeSlots } from 'hooks/useAvailableTimeSlots';

import BigCalendar from '../../../components/bigCalendar/Booking';
import Error from '../../error/pages/Error';
import Loader from '../../../components/layouts/Loader';
import Footer from '../../../components/layouts/Footer';
import formatDuration from '../../../utils/durationUtils';
import { isPageTypeIrregular } from '../../../utils/calendarUtils';

function BookingPage(props) {
  const params = useParams();
  const duration = formatDuration(params.duration);
  const calendarDetail = useCalendarDetails();
  const { feature } = props;
  const isIrregular = isPageTypeIrregular(calendarDetail, feature);
  const updateTimezoneAction = createAction('timezone_update');
  const dispatch = useDispatch();

  useEffect(() => {
    (async function onMount() {
      if (isPageTypeIrregular(calendarDetail, feature)) {
        await dispatch(
          updateTimezoneAction({
            timezone: { label: formatTimezone(), code: getDefaultTimezone() },
          }),
        );
        props.history.push(`/${params.calendar_alias}/confirmation`);
      }

      if (calendarDetail.isSuccess && duration) {
        pushEvent({ ...events.triggerPageView('Timeslots') });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarDetail?.isSuccess,
    calendarDetail?.pageType,
    duration,
    params,
    dispatch,
  ]);

  if (isIrregular) {
    return null;
  }

  if (calendarDetail?.pageType != null && !duration) {
    return (
      <Error message="予約ページが見つかりません。お手数ですが、もう一度お試しください。" />
    );
  }

  if (calendarDetail.hasError) {
    return <Error message={calendarDetail.message} />;
  }

  if (!calendarDetail.isSuccess) {
    return <Loader />;
  }

  function handleFormSubmit(selected) {
    props.change('selectedHours', selected);
    props.history.push({
      pathname: `/${params.calendar_alias}/${duration}min/confirmation`,
    });
  }

  return (
    <>
      <Field name="selectedHours" component="input" hidden />
      <BigCalendar
        duration={duration}
        useAvailableTimeSlots={useAvailableTimeSlots}
        calendarDetail={calendarDetail}
        businessOpeningTime={calendarDetail.businessOpeningTime}
        businessClosingTime={calendarDetail.businessClosingTime}
        handleChange={handleFormSubmit}
      />
      <Footer mt={['55px', '18px']} />
    </>
  );
}

BookingPage.propTypes = {
  change: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  feature: PropTypes.string.isRequired,
};

export const Booking = reduxForm({
  form: 'BookingDate',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
})(BookingPage);
