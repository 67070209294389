import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar } from '@d-lighted/design-system';
import { isPageTypeIrregular } from 'utils/calendarUtils';
import { useIntl } from 'react-intl';

/** Local Import */
import { Row, Container } from '../utils/Helper';
import { useHeaderFormatDuration } from '../../utils/durationUtils';
import messages from './i18n/header';

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
`;

const Nav = styled.div`
  min-height: 104px;
  background: white;
  align-items: center !important;
  display: flex !important;
`;

const NavBrand = styled.div`
  margin-left: 10px;
  margin-top: 5px;
  float: left !important;
  padding-top: 25px;
  padding-bottom: 25px;
`;

const NavTitle = styled.div`
  font-family: Noto Sans Japanese;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 1.05px;
  text-align: left;
  color: #314143;
`;

const NavDescription = styled.div`
  font-family: Noto Sans Japanese;
  font-size: 12px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #68878d;
  margin-left: 2px;
  white-space: pre-wrap;
`;

function Header(props) {
  const { feature } = props;
  const params = useParams();
  const calendarDetail = useSelector(state => state.calendarDetail);
  const { title, description } = calendarDetail;
  const slotsHasError = useSelector(state => state.timeSlots.hasError);
  const duration = useHeaderFormatDuration(params.duration);
  const icon = useSelector(state => state.calendarDetail.icon?.url);
  const isIrregular = isPageTypeIrregular(calendarDetail, feature);
  const { bookingCalendarMultiguestScheduledEvent } = useSelector(
    state => state.multiguestEvent,
  );
  const intl = useIntl();

  const handleEmptyHeader = () =>
    title && (isIrregular || duration) && !slotsHasError ? (
      <>
        <StyledRow>
          {icon && (
            <Avatar
              height="56px"
              width="56px"
              mr="10px"
              src={icon}
              alt="profile"
            />
          )}
          <div>
            <NavTitle className="nav_header_brand_text_big">
              {bookingCalendarMultiguestScheduledEvent
                ? `${intl.formatMessage(messages.scheduleAdjustment)}:${
                    bookingCalendarMultiguestScheduledEvent.title
                  } - ${
                    bookingCalendarMultiguestScheduledEvent.duration
                  }${intl.formatMessage(messages.minute)}`
                : `${title}`}
              {isIrregular || bookingCalendarMultiguestScheduledEvent
                ? ''
                : ` - ${duration}`}
            </NavTitle>
            <NavDescription>
              {bookingCalendarMultiguestScheduledEvent
                ? bookingCalendarMultiguestScheduledEvent.description
                : description}
            </NavDescription>
          </div>
        </StyledRow>
      </>
    ) : (
      ''
    );
  return (
    <Nav>
      <Container>
        <NavBrand>{handleEmptyHeader()}</NavBrand>
      </Container>
    </Nav>
  );
}

Header.defaultProps = {};

Header.propTypes = {
  feature: PropTypes.string.isRequired,
};

export default Header;
