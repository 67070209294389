import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const Center = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  min-height: 70vh;
`;
